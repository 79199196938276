/* --------------------------------------------------------------
    Import CCS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}

body {
  height: 100%;
  font-family:
    'Open Sans',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  text-rendering: optimizeLegibility;
  color: #555555;
}

.wrap {
  padding: 30px 0;
}

.container-fluid.but-fixed {
  max-width: 1356px;
}
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}

.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #f5f5f5;
  color: #666;
  border-top: 1px solid #e4e4e4;

  p {
    margin: 0 0 5px 0;
  }

  a {
    color: #666;
  }

  .social a {
    border: 0;
  }

  img {
    display: inline-block;
  }
}

.bg-light-gray {
  background-color: #f5f5f5;
}

.border-light {
  border-color: #e2edf6 !important;
}

.blue {
  color: #3187dd;
  &.dark {
    color: darken(#3187dd, 8%);
  }
}
.fw-semi-bold {
  font-weight: 600 !important;
}

/* --------- asc styles --------- */
#header {
  a.logo {
    float: left;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    width: 80%;
    max-width: 650px;

    img {
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
      width: auto;
      max-width: 40px;
      max-height: 40px;
    }

    span.big,
    span.small {
      display: inline;
      font-size: 1.5em;
      font-weight: 500;
      color: #555;
      line-height: 1em;
      vertical-align: middle;
    }

    span.big {
      font-weight: 600;
      font-family:
        'Open Sans',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        Roboto,
        'Helvetica Neue',
        Arial,
        'Noto Sans',
        sans-serif,
        'Apple Color Emoji',
        'Segoe UI Emoji',
        'Segoe UI Symbol',
        'Noto Color Emoji';
    }

    span.small {
      display: none;
      margin-top: 0;
      font-size: 16px;
      font-family: 'Open Sans', Verdana;
      font-weight: normal;
    }
  }

  #support {
    display: none;
    float: right;

    p {
      strong {
        font-size: 16px;
      }
    }

    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;

      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }

      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }

    span.phone {
      display: none;
    }

    a.livechat {
      color: #555;

      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
  }
}

#header a.logo {
  width: 75%;
  max-width: 390px;
}

#header a.logo img {
  float: left;
  max-width: 35px;
  max-height: 35px;
  margin-right: 12px;
}

#header a.logo span.big,
#header a.logo span.small {
  display: inline-block;
  margin-top: 5px;
  font-weight: 400;
  font-family: 'Roboto Slab', 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica;
  line-height: 25px;
  font-size: 1.8em;
}

#header a.logo span.big span {
  font-size: 14px;
}

#header a.logo span.small {
  display: none;
  margin-top: -12px;
  font-size: 0.9em;
  font-family: 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica;
  line-height: 1.6em;
}

#header .dropdown-list {
  clear: right;
  float: right;
  width: 100%;
  max-width: 300px;

  .drop {
    z-index: 100;
  }
}

/* BANNER */

#banner {
  h1,
  h2 {
    z-index: 1;
    position: relative;
  }
  h1 {
    font-size: 2.15em;
    color: #3187dd;
    line-height: 1em;
    span {
      display: inline-block;
      font-size: 0.65em;
      color: #444;
    }
  }
  .drop {
    max-width: 220px;
    left: 0;
    right: 0;
    top: 45px;
    max-width: 280px;
  }
  ul.check {
    display: inline-block;
    text-align: left;
    position: relative;
    z-index: 1;
  }

  .cta-btn.green {
    background: #2ecc71;
    height: inherit;
    z-index: 1;
    max-width: 280px;
    &:hover {
      background: darken(#31d878, 5%);
    }
  }

  .cta-btn.green span {
    display: block;
    font-size: 14px;
    font-family: 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
    text-transform: none;
  }
}

#banner:after,
#banner.state:after {
  background: transparent url('../images/banners/banner-bg.jpg') no-repeat 100% 0 / cover;
  content: '';
  display: block;
  height: 100%;
  left: 0;
  opacity: 0.25;
  position: absolute;
  top: 0;
  width: 100%;
}
#banner.state:after {
  background: transparent;
}
#banner.florida:after {
  background: transparent url(../images/banners/banner-bg-fl.jpg) no-repeat 100% 0 / cover;
}
#banner.ron:after {
  background: transparent url(../images/banners/banner-bg-fl-ron.jpg) no-repeat 100% 0 / cover;
}
#banner.renewal:after {
  background: transparent url(../images/banners/banner-bg-fl-renewal.jpg) no-repeat 100% 0 / cover;
  opacity: 0.15;
}
#banner.alabama:after {
  background: transparent url(../images/banners/banner-bg-al.jpg) no-repeat 100% 0 / cover;
}
#banner.alaska:after {
  background: transparent url(../images/banners/banner-bg-ak.jpg) no-repeat 100% 0 / cover;
}
#banner.arizona:after {
  background: transparent url(../images/banners/banner-bg-az.jpg) no-repeat 100% 0 / cover;
}
#banner.arkansas:after {
  background: transparent url(../images/banners/banner-bg-ar.jpg) no-repeat 100% 0 / cover;
}
#banner.california:after {
  background: transparent url(../images/banners/banner-bg-ca.jpg) no-repeat 100% 0 / cover;
}
#banner.georgia:after {
  background: transparent url(../images/banners/banner-bg-ge.jpg) no-repeat 100% 0 / cover;
}
#banner.hawaii:after {
  background: transparent url(../images/banners/banner-bg-hi.jpg) no-repeat 100% 0 / cover;
}
#banner.idaho:after {
  background: transparent url(../images/banners/banner-bg-id.jpg) no-repeat 100% 0 / cover;
}
#banner.illinois:after {
  background: transparent url(../images/banners/banner-bg-il.jpg) no-repeat 100% 0 / cover;
}
#banner.indiana:after {
  background: transparent url(../images/banners/banner-bg-in.jpg) no-repeat 100% 0 / cover;
}
#banner.kansas:after {
  background: transparent url(../images/banners/banner-bg-ks.jpg) no-repeat 100% 0 / cover;
}
#banner.kentucky:after {
  background: transparent url(../images/banners/banner-bg-ky.jpg) no-repeat 100% 0 / cover;
}
#banner.massachusetts:after {
  background: transparent url(../images/banners/banner-bg-ma.jpg) no-repeat 100% 0 / cover;
}
#banner.michigan:after {
  background: transparent url(../images/banners/banner-bg-mi.jpg) no-repeat 100% 0 / cover;
}
#banner.mississippi:after {
  background: transparent url(../images/banners/banner-bg-ms.jpg) no-repeat 100% 0 / cover;
}
#banner.missouri:after {
  background: transparent url(../images/banners/banner-bg-mo.jpg) no-repeat 100% 0 / cover;
}
#banner.montana:after {
  background: transparent url(../images/banners/banner-bg-mt.jpg) no-repeat 100% 0 / cover;
}
#banner.nebraska:after {
  background: transparent url(../images/banners/banner-bg-ne.jpg) no-repeat 100% 0 / cover;
}
#banner.nevada:after {
  background: transparent url(../images/banners/banner-bg-nv.jpg) no-repeat 100% 0 / cover;
}
#banner.new-mexico:after {
  background: transparent url(../images/banners/banner-bg-nm.jpg) no-repeat 100% 0 / cover;
}
#banner.new-york:after {
  background: transparent url(../images/banners/banner-bg-ny.jpg) no-repeat 100% 0 / cover;
}
#banner.new-carolina:after {
  background: transparent url(../images/banners/banner-bg-nc.jpg) no-repeat 100% 0 / cover;
}
#banner.north-dakota:after {
  background: transparent url(../images/banners/banner-bg-nd.jpg) no-repeat 100% 0 / cover;
}
#banner.ohio:after {
  background: transparent url(../images/banners/banner-bg-oh.jpg) no-repeat 100% 0 / cover;
}
#banner.oklahoma:after {
  background: transparent url(../images/banners/banner-bg-ok.jpg) no-repeat 100% 0 / cover;
}
#banner.pennsylvania:after {
  background: transparent url(../images/banners/banner-bg-pa.jpg) no-repeat 100% 0 / cover;
}
#banner.south-carolina:after {
  background: transparent url(../images/banners/banner-bg-sc.jpg) no-repeat 100% 0 / cover;
}
#banner.south-dakota:after {
  background: transparent url(../images/banners/banner-bg-sd.jpg) no-repeat 100% 0 / cover;
}
#banner.tennessee:after {
  background: transparent url(../images/banners/banner-bg-tn.jpg) no-repeat 100% 0 / cover;
}
#banner.texas {
  &:after {
    background: transparent url(../images/banners/banner-bg-tx.jpg) no-repeat 100% 0 / cover;
  }
  &.lp:after {
    background: transparent url(../images/banners/banner-bg-tx-lp.jpg) no-repeat 100% 0 / cover;
  }
}
#banner.utah:after {
  background: transparent url(../images/banners/banner-bg-ut.jpg) no-repeat 100% 0 / cover;
}
#banner.washington:after {
  background: transparent url(../images/banners/banner-bg-wa.jpg) no-repeat 100% 0 / cover;
}
#banner.wisconsin:after {
  background: transparent url(../images/banners/banner-bg-wi.jpg) no-repeat 100% 0 / cover;
}
#banner.wyoming:after {
  background: transparent url(../images/banners/banner-bg-wy.jpg) no-repeat 100% 0 / cover;
}

#banner .action {
  z-index: 1;
}

.list-blog-entry .entry-header span {
  color: #ccc;
}

#howitworks,
.howitworks {
  background: #f0f9ff;

  h3 {
    span.blue {
      font-size: 0.8em;
    }
  }

  .reg_new {
    &:hover {
      color: #e94c48;
    }
    small {
      font-size: 50%;
      position: relative;
      top: -5px;
    }
  }

  .icon {
    width: 80px;
    height: 80px;
    background: url('../images/how-it-works-icons.png') no-repeat 0 0 / cover;
  }

  .icon.send {
    background-position: -80px 0;
  }

  .icon.certified {
    background-position: -160px 0;
  }

  .icon.sign {
    background-position: -240px 0;
  }

  &.ron {
    .icon {
      display: inline-block;
      margin-bottom: 10px;
      width: 80px;
      height: 80px;
      background: url('../images/ron-steps-icons.png') no-repeat 0 0 / cover;
    }

    .icon.complete {
      background-position: 0 0;
    }

    .icon.send {
      background-position: -80px 0;
    }

    .icon.approved {
      background-position: -160px 0;
    }
  }
}

.article-header span {
  color: #ccc;
}
#article-container {
  .article-image {
    object-fit: cover;
  }
  *:disabled {
    background-color: #ebebeb;
  }
}
.card.wrap {
  padding-top: 40px;
  padding-bottom: 30px;
  border-bottom: 1px solid #e2edf6;
  background: #fff;

  h3 {
    margin-bottom: 0.65em;
  }

  .btn.pill.normal {
    margin: 0;
    margin-top: 0.65em;
    padding: 9px;
    font-family: 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
    font-size: 1em;
    text-transform: none;
    color: #2ecc71;
    border-color: #2ecc71;
    max-width: 250px;
  }
}

.become_notary {
  max-width: 400px;
}

.state-flag_wrap {
  width: 158px;
  height: 108px;
}

.state-flag {
  width: 150px;
  height: 100px;
  background: #fff url('../images/state-flags.jpg') no-repeat scroll 0 0;
}

.arizona .state-flag {
  background-position: 0 0;
}

.florida .state-flag {
  background-position: 0 -100px;
}

.illinois .state-flag {
  background-position: 0 -200px;
}

.indiana .state-flag {
  background-position: 0 -300px;
}
.kansas .state-flag {
  background-position: 0 -400px;
}
.michigan .state-flag {
  background-position: 0 -500px;
}
.missouri .state-flag {
  background-position: 0 -600px;
}
.pennsylvania .state-flag {
  background-position: 0 -700px;
}
.texas .state-flag {
  background-position: 0 -800px;
}
.washington .state-flag {
  background-position: 0 -900px;
}
.california .state-flag {
  background-position: 0 -1000px;
}
.tennessee .state-flag {
  background-position: 0 -1100px;
}
.alabama .state-flag {
  background-position: 0 -1200px;
}
.new-mexico .state-flag {
  background-position: 0 -1300px;
}
.north-dakota .state-flag {
  background-position: 0 -1400px;
}
.arkansas .state-flag {
  background-position: 0 -1500px;
}
.kentucky .state-flag {
  background-position: 0 -1600px;
}
.mississippi .state-flag {
  background-position: 0 -1700px;
}
.oklahoma .state-flag {
  background-position: 0 -1800px;
}
.wisconsin .state-flag {
  background-position: 0 -1900px;
}
.nevada .state-flag {
  background-position: 0 -2000px;
}
.nebraska .state-flag {
  background-position: 0 -2100px;
}
.utah .state-flag {
  background-position: 0 -2200px;
}
.idaho .state-flag {
  background-position: 0 -2300px;
}
.alaska .state-flag {
  background-position: 0 -2400px;
}
.hawaii .state-flag {
  background: #fff url('../images/state-flags/hawaii.jpg') no-repeat scroll 0 0;
}
.montana .state-flag {
  background: #fff url('../images/state-flags/montana.jpg') no-repeat scroll 0 0;
}
.new-york .state-flag {
  background: #fff url('../images/state-flags/new-york.jpg') no-repeat scroll 0 0;
}
.south-dakota .state-flag {
  background: #fff url('../images/state-flags/south-dakota.jpg') no-repeat scroll 0 0;
}
.wyoming .state-flag {
  background: #fff url('../images/state-flags/wyoming.jpg') no-repeat scroll 0 0;
}
.ohio .state-flag {
  background: #fff url('../images/state-flags/ohio.jpg') no-repeat scroll 0 0;
}
.georgia .state-flag {
  background: #fff url('../images/state-flags/georgia.jpg') no-repeat scroll 0 0;
}
.virginia .state-flag {
  background: #fff url('../images/state-flags/virginia.jpg') no-repeat scroll 0 0;
}
.north-carolina .state-flag {
  background: #fff url('../images/state-flags/north-carolina.jpg') no-repeat scroll 0 0;
}
.massachusetts .state-flag {
  background: #fff url('../images/state-flags/massachusetts.jpg') no-repeat scroll 0 0;
}
.south-carolina .state-flag {
  background: #fff url('../images/state-flags/south-carolina.jpg') no-repeat scroll 0 0;
}

/* ASIDE */
#aside {
  .cta-btn.green.link {
    font-size: 18px;
  }
  textarea {
    min-height: 120px;
  }
  .btn.submit {
    min-width: 150px;
  }
}
.course {
  h2 {
    margin-left: -16px;
    margin-right: -16px;
    background: #3187dd;
  }
}
.red {
  color: #c30f0f;
}
table#downloads td:last-child {
  min-width: 100px;
}
.bg-dark-blue {
  background-color: #0059b3 !important;
}

#notary {
  .price {
    font-size: 1.8rem;
    margin-top: 3px;
  }
  .icon-check {
    color: #2ecc71;
  }
  .frfc,
  .lrfc {
    border-left: none !important;
    border-bottom: none !important;
  }
  thead {
    tr {
      border-top: 0 !important;
    }
  }
  th:nth-child(4) {
    border-top: 2px solid #4b96e1;
    border-left: 2px solid #4b96e1;
    border-right: 2px solid #4b96e1;
    border-bottom: none;
  }
  td:nth-child(4) {
    border-left: 2px solid #4b96e1;
    border-right: 2px solid #4b96e1;
  }
  tr:last-child {
    td:nth-child(4) {
      border-bottom: 2px solid #4b96e1;
    }
  }
}

.table-fixed {
  table-layout: fixed;
}
.long {
  min-width: 280px;
}

#heading-override {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-weight: 700;
    font-family: var(--font-family);
    color: #1c4976;
  }
  .cta-btn.green span {
    display: block;
    font-size: 14px;
    font-family: 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
    text-transform: none;
  }
}

#banner-wrap {
  z-index: 1;
  .overlay {
    background: rgba(218, 233, 248, 0.92);
  }
  &.home {
    background: #fff url(../images/banners/landing-bg.jpg) no-repeat calc(50% - 100px) / cover;
    #banner {
      position: relative;
      h1 {
        line-height: 30px;
      }
    }
  }
}
.bg-blue {
  background: #1c4976;
}
.gradient-wrap {
  background: #1c4976;
  background: linear-gradient(124deg, #1c4976, #094f7a 34%, #517fad);
  a {
    color: #98def9;
  }
}
.numbered-box {
  background: #4183c4;
  line-height: 14px;
}
/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  #header {
    a.logo img {
      max-width: 40px;
      max-height: 40px;
    }

    a.logo span.big {
      font-size: 2em;
    }
  }

  #banner {
    h1 {
      font-size: 2.5em;
    }
    h1 span {
      font-size: 0.6em;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }

  #header a.logo {
    img {
      max-width: 60px;
      max-height: 60px;
    }
  }
  #banner {
    .price-box {
      background-color: #fff;
      border: 1px solid $success;
    }
  }
  #banner:after,
  #banner.state:after {
    background-size: contain;
  }
  #notary {
    thead {
      tr {
        border-top: none !important;
      }
    }
    tbody {
      tr:last-child {
        th {
          border-bottom: none !important;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  #banner.renewal:after {
    background: none;
    opacity: 0.15;
  }
  #banner.lp:after {
    display: none !important;
  }
  #banner.state {
    background: transparent url(../images/banners/banner-bg.jpg) no-repeat 100% 0 / contain;
  }
  #banner.state.alabama {
    background: transparent url(../images/banners/banner-bg-al.jpg) no-repeat 100% 0 / contain;
  }
  #banner.state.alaska {
    background: transparent url(../images/banners/banner-bg-ak.jpg) no-repeat 100% 0 / contain;
  }

  #banner.state.arizona {
    background: transparent url(../images/banners/banner-bg-az.jpg) no-repeat 100% 0 / contain;
  }

  #banner.state.arkansas {
    background: transparent url(../images/banners/banner-bg-ar.jpg) no-repeat 100% 0 / contain;
  }

  #banner.state.california {
    background: transparent url(../images/banners/banner-bg-ca.jpg) no-repeat 100% 0 / contain;
  }

  #banner.state.florida {
    background: transparent url(../images/banners/banner-bg-fl.jpg) no-repeat 100% 0 / contain;
  }

  #banner.state.georgia {
    background: transparent url(../images/banners/banner-bg-ge.jpg) no-repeat 100% 0 / contain;
  }

  #banner.state.hawaii {
    background: transparent url(../images/banners/banner-bg-hi.jpg) no-repeat 100% 0 / contain;
  }

  #banner.state.idaho {
    background: transparent url(../images/banners/banner-bg-id.jpg) no-repeat 100% 0 / contain;
  }

  #banner.state.illinois {
    background: transparent url(../images/banners/banner-bg-il.jpg) no-repeat 100% 0 / contain;
  }

  #banner.state.indiana {
    background: transparent url(../images/banners/banner-bg-in.jpg) no-repeat 100% 0 / contain;
  }

  #banner.state.kansas {
    background: transparent url(../images/banners/banner-bg-ks.jpg) no-repeat 100% 0 / contain;
  }

  #banner.state.kentucky {
    background: transparent url(../images/banners/banner-bg-ky.jpg) no-repeat 100% 0 / contain;
  }

  #banner.state.massachusetts {
    background: transparent url(../images/banners/banner-bg-ma.jpg) no-repeat 100% 0 / contain;
  }

  #banner.state.michigan {
    background: transparent url(../images/banners/banner-bg-mi.jpg) no-repeat 100% 0 / contain;
  }

  #banner.state.mississippi {
    background: transparent url(../images/banners/banner-bg-ms.jpg) no-repeat 100% 0 / contain;
  }

  #banner.state.missouri {
    background: transparent url(../images/banners/banner-bg-mo.jpg) no-repeat 100% 0 / contain;
  }

  #banner.state.montana {
    background: transparent url(../images/banners/banner-bg-mt.jpg) no-repeat 100% 0 / contain;
  }

  #banner.state.nebraska {
    background: transparent url(../images/banners/banner-bg-ne.jpg) no-repeat 100% 0 / contain;
  }

  #banner.state.nevada {
    background: transparent url(../images/banners/banner-bg-nv.jpg) no-repeat 100% 0 / contain;
  }

  #banner.state.new-mexico {
    background: transparent url(../images/banners/banner-bg-nm.jpg) no-repeat 100% 0 / contain;
  }

  #banner.state.new-york {
    background: transparent url(../images/banners/banner-bg-ny.jpg) no-repeat 100% 0 / contain;
  }

  #banner.state.north-carolina {
    background: transparent url(../images/banners/banner-bg-nc.jpg) no-repeat 100% 0 / contain;
  }

  #banner.state.north-dakota {
    background: transparent url(../images/banners/banner-bg-nd.jpg) no-repeat 100% 0 / contain;
  }

  #banner.state.ohio {
    background: transparent url(../images/banners/banner-bg-oh.jpg) no-repeat 100% 0 / contain;
  }

  #banner.state.oklahoma {
    background: transparent url(../images/banners/banner-bg-ok.jpg) no-repeat 100% 0 / contain;
  }

  #banner.state.pennsylvania {
    background: transparent url(../images/banners/banner-bg-pa.jpg) no-repeat 100% 0 / contain;
  }

  #banner.state.south-carolina {
    background: transparent url(../images/banners/banner-bg-sc.jpg) no-repeat 100% 0 / contain;
  }

  #banner.state.south-dakota {
    background: transparent url(../images/banners/banner-bg-sd.jpg) no-repeat 100% 0 / contain;
  }

  #banner.state.tennessee {
    background: transparent url(../images/banners/banner-bg-tn.jpg) no-repeat 100% 0 / contain;
  }

  #banner.state.texas {
    background: transparent url(../images/banners/banner-bg-tx.jpg) no-repeat 100% 0 / contain;
    &.lp {
      background: transparent url(../images/banners/banner-bg-tx-lp.jpg) no-repeat 100% 0 / contain;
    }
  }
  #banner.state.utah {
    background: transparent url(../images/banners/banner-bg-ut.jpg) no-repeat 100% 0 / contain;
  }

  #banner.state.washington {
    background: transparent url(../images/banners/banner-bg-wa.jpg) no-repeat 100% 0 / contain;
  }

  #banner.state.wisconsin {
    background: transparent url(../images/banners/banner-bg-wi.jpg) no-repeat 100% 0 / contain;
  }
  #banner.state.wyoming {
    background: transparent url(../images/banners/banner-bg-wy.jpg) no-repeat 100% 0 / contain;
  }

  #banner.state.ron {
    background: transparent url(../images/banners/banner-bg-fl-ron.jpg) no-repeat 100% 0 / contain;
  }

  #banner.state.renewal {
    background: transparent url(../images/banners/banner-bg-fl-renewal.jpg) no-repeat 100% 0 / contain;
  }

  .card.action {
    height: 75px;
  }
  #banner-wrap {
    background-size: auto 392px;
    .overlay {
      background: linear-gradient(115deg, #dae9f8 0%, #dae9f8 58%, rgba(0, 0, 0, 0%) 58.1%);
      background: -moz-linear-gradient(-20deg, #dae9f8 0%, #dae9f8 58%, rgba(0, 0, 0, 0%) 58%);
    }
    .text-wrap {
      max-width: 530px;
    }
    &.home {
      background: #fff url(../images/banners/landing-bg.jpg) no-repeat calc(50% + 328px) 50% / contain;
    }
    &.texas {
      background: #fff url(../images/banners/banner-bg-tx-new.jpg) no-repeat calc(50% + 250px) 0 / contain;
    }
  }
}

@media (min-width: 1200px) {
  #banner:after {
    display: none !important;
  }

  #banner {
    background: transparent url(../images/banners/banner-bg.jpg) no-repeat 100% 0 / contain;
  }

  #banner {
    h1 {
      font-size: 3.25em;
    }

    h1 span {
      font-size: 0.65em;
    }
  }
  #banner-wrap {
    .overlay {
      background: linear-gradient(115deg, #dae9f8 0%, #dae9f8 50%, rgba(0, 0, 0, 0%) 50.1%);
      background: -moz-linear-gradient(-20deg, #dae9f8 0%, #dae9f8 50%, rgba(0, 0, 0, 0%) 50%);
    }
    &.home {
      background: #fff url(../images/banners/landing-bg.jpg) no-repeat calc(50% + 249px) 50% / auto 408px;
    }
    &.texas {
      background: #fff url(../images/banners/banner-bg-tx-new.jpg) no-repeat calc(50% + 355px) 0 / contain;
    }
  }
}
